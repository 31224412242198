import React from 'react';
import Layout from '../components/layout';
import Hero from '../components/hero';
import SectionLeft from '../components/sectionLeft';
import SectionRight from '../components/sectionRight';
import {Link} from 'gatsby';

import HeroImage from '../images/epic_tail_whip.jpg';
import AmazonImage from '../images/amazon.jpg';
import EbayImage from '../images/ebay.jpg';
import WalmartImage from '../images/walmart.jpg';
import PartsImage from '../images/parts.png';
import GoogleImage from '../images/google.jpg';
import VideoButton from '../components/videoButton';
import DemoFooter from '../components/demoFooter';
import SEO from '../components/seo';

export default function () {
    return (
        <Layout>
            <SEO
                title={'Powersports P&A Listing Tool'}
                description={'Powersports & Motorcycle parts & accessories listing tool that comes preloaded with over 2 million parts.'}
            />
            <Hero image={HeroImage} justifyEnd={false} backgroundPosition={'60% center'}>
                <div className={'col col-lg-6 pl-lg-5 align-self-center text-white'}>
                    <h1
                        style={{
                            fontFamily: 'Anton',
                            fontWeight: 400,
                            fontSize: '4rem',
                        }}
                    >
                        STREAMLINE EVERY ASPECT OF YOUR OPERATION
                    </h1>
                </div>
            </Hero>

            <div className={'row justify-content-center my-5'}>
                <div className={'col col-md-6 text-center border-top border-bottom'}>
                    <h6 style={{lineHeight: '1.9em'}} className={'font-weight-bold'}>
                        With it’s extensive catalog of Aftermarket and OEM products, PSS gives you the ability to easily create and edit listings on eBay. With the click of a button, you can list our
                        cleanly formatted catalog data directly to eBay. At the same time, it syncs these listings with price & inventory feeds from all the major distributors to eliminate overselling
                        and gives you all the shipping options you need to build loyal, long-term customers.
                    </h6>
                </div>
            </div>

            <SectionRight image={EbayImage} alt={'Ebay Logo'} title={'VIP: The Better Way to eBay'} id={'ebay'}>
                <p className={'lead'}>
                    If you’re a high-volume eBay seller, have your own store on the site, or use it for special offers or to clear old inventory, PSS gives you the power to easily list and manage your
                    inventory while keeping it synced with and up
                    to date across all your other sales channels. That helps you avoid frustrating stock-outs, overselling, and other issues associated with listing on the auction site.
                </p>

                <Link to={'/ebay-monthly-fee-savings-calculator'} className={'btn btn-lg btn-outline-primary w-100 mb-3 d-none d-md-block'}>VIP Savings Comparison</Link>
                <VideoButton videoId={'nJFcKOhDOL0'} label={'Watch eBay VIP Video'}/>
                <VideoButton videoId={'pf4-ytNU4KU'} label={'Watch eBay Video'}/>
            </SectionRight>

            <DemoFooter/>

        </Layout>
    );
}
